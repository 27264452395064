@import '_mixins.scss';
@import '_variables.scss';
@import './typography';
@import './utilities';
@import './fonts';

@font-face {
  font-family: 'quincy_cfextra_bold';
  src: url('/fonts/QuincyCF-ExtraBold.woff2') format('woff2'),
    url('/fonts/QuincyCF-ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'quincy_cfregular';
  src: url('/fonts/QuincyCF-Regular.woff2') format('woff2'),
    url('/fonts/QuincyCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-regular';
  src: url('/fonts/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat_semibold';
  src: url('/fonts/Montserrat-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono';
  src: url('/fonts/RobotoMono-VariableFont.ttf');
  font-style: normal;
  font-weight: 100 1000;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family-montserrat;
  background-color: $slate-50;
  color: $slate;
}

input,
textarea {
  color: $slate-600;
  &::placeholder {
    color: $slate-400;
  }
}

.container {
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  padding: 32px;
  background-color: white;
  box-shadow: 0px 4px 14px rgb(10 36 66 / 3%);
  border-radius: 16px;
  position: relative;
  &-outlined {
    padding: 40px 32px;
    border: 8px solid $slate;
    &-purple {
      background-color: $blue-200;
      border-color: $blue-300;
    }
  }
}

.checkbox_label {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: arial;
  margin-top: 4px;

  .checkbox_show_checkbox {
    background-image: url('../public/images/checkbox_unchecked.svg');
  }

  & > .checkbox_input {
    display: none;
  }

  & > .checkbox_show {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    background-size: cover;
    border-radius: 4px;
  }

  & > .checkbox_input:checked + .checkbox_show_checkbox {
    background-image: url('../public/images/checkbox_checked.svg');
    background-position: center;
  }
}

.dark-label,
.twilight-label,
.twilight-label-green {
  background: rgba(6, 22, 41, 0.5);
  border-radius: 16px;
}

.gray-label,
.gray-label-green {
  background-color: $slate-100;
  border-radius: 16px;
}

.dark-label,
.gray-label {
  padding: 8px;
}

.twilight-label,
.twilight-label-green,
gray-label-green {
  padding: 8px 12px;
}

.icon-button {
  background: $slate;
  // border: 1px solid #0a2442;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  max-height: 34px;
  display: flex;
  gap: 8px;
  color: $slate-300;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  font-family: $font-family-roboto;
  align-items: center;
  min-width: 34px;
  justify-content: center;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  &:hover {
    background: $slate-800;
    color: white;
  }

  &:focus {
    background: $slate-800;
    color: white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f2f4ff;
  }

  &:active {
    background: $slate-300;
    color: white;
  }
}

@include md {
}

@include sm {
  .card {
    padding: 32px 16px;
  }

  .icon-button {
    padding: 9px;
    min-width: 24px;
    // max-height: 24px;
  }

  .dark-label {
    padding: 8px;
  }

  .twilight-label {
    background-color: $slate-100;
  }
  .twilight-label-green,
  .gray-label-green {
    background-color: $green-300;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type='button'],
button,
input[type='submit'] {
  -webkit-appearance: none;
}

.uploader-error {
  display: flex;
  justify-content: center;
  gap: 8px;

  i {
    position: relative;
    width: 24px;
    height: 24px;
  }

  span {
    color: $red;
    font-size: 14px;
    line-height: 24px;
    font-family: $font-family-roboto;
  }
}

.left {
  justify-content: flex-start;
}

.clickable {
  cursor: pointer;
}

.no-decoration {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: $blue;

  &:hover {
    color: $blue-600;
  }
}
