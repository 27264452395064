/** COLORS */
// Blue
$blue: #6979ff;
$blue-100: #fafaff;
$blue-200: #f2f4ff;
$blue-300: #d9ddff;
$blue-350: #a9b4ff;
$blue-400: #99a4ff;
$blue-600: #4552c5;
$blue-700: #323b8c;
$blue-800: #283073;
$blue-900: #000640;

// Slate
$slate: #0a2442;
$slate-50: #f9fafb;
$slate-100: #f2f4f7;
$slate-200: #e4e7ec;
$slate-300: #d0d5dd;
$slate-400: #98a2b3;
$slate-500: #667085;
$slate-600: #475467;
$slate-800: #061629;

// Orange
$orange: #ffe282;
$orange-200: #fffbf0;
$orange-300: #fff6d9;
$orange-400: #ffeaa6;
$orange-600: #ffc300;
$orange-800: #f47601;

// Cyan
$cyan: #7ee8ff;
$cyan-200: #f2fdff;
$cyan-300: #d9f8ff;
$cyan-400: #b2f1ff;
$cyan-800: #006980;
$cyan-900: #003f4d;

// Red
$red: #ff6165;
$red-200: #ffeded;
$red-300: #ffd9d9;
$red-600: #ff3333;
$red-800: #a10000;
$red-900: #4d0012;
$error-500: #f04438;
$error-25: #fffbfa;

// Green
$green: #67f5ba;
$green-200: #f2fffa;
$green-300: #e0fff2;
$green-400: #bfffe4;
$green-600: #35e19a;
$green-700: #00c271;
$green-800: #009e5c;
$green-900: #004d2d;

/** FONTS */
$font-family-montserrat: 'montserrat';
$font-family-montserrat-semi: 'montserrat_semibold';
$font-family-quincy-regular: 'quincy_cfregular';
$font-family-quincy-bold: 'quincy_cfextra_bold';
$font-family-roboto: 'Roboto Mono', monospace;
$font-family-montserrat-global: 'Montserrat';

/** TIMEOUTS */
$very-fast-transition: 0.15s;
$fast-transition: 0.25s;
