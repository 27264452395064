@import '../styles/variables';

.terms_card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5rem;
  background-color: $slate-50;
  border-radius: 0.5rem;
  text-decoration: none;

  input[type='checkbox'] {
    position: relative;
    box-sizing: border-box;
    margin-right: 1rem;
    top: 5px;
  }

  main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    p {
      font-family: $font-family-montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: $slate;

      .strong {
        font-weight: 700;
      }

      .light {
        font-weight: 400;
        color: $slate-500;

        a {
          color: $blue;
          font-weight: bold;
        }
      }
    }

    ul {
      @extend p;
      list-style: none;
      gap: 16px;
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      li {
        &::before {
          content: '•';
          color: #475467;
          font-weight: 500;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }

  .terms_card:not(:last-child) {
    margin-bottom: 2rem;
  }
}
